import CardList from "../CardList/CardList";

function Page({ title, list }) {
  return (
    <>
      <h1>{title}</h1>
      <CardList list={list} />
    </>
  );
}

export default Page;

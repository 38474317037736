import "./Card.css";

function Card({ info, index }) {
  const handleClick = (e) => {
    console.log(e.target.closest(".flip-card-inner"));
    e.target.closest(".flip-card-inner").style.transform = "rotateY(180deg)";
  };
  return (
    <div className='flip-card' key={index} onClick={handleClick}>
      <div className='flip-card-inner'>
        <div className='flip-card-front'>
          <div>SELECT ME</div>
        </div>
        <div className='flip-card-back'>
          {/* <h1>John Doe</h1> */}
          <p>{info}</p>
        </div>
      </div>
    </div>
  );
}

export default Card;

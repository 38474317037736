import "./App.css";
import Page from "./components/Page/Page";
import {
  NAME_3_THINGS_TITLE,
  YOUR_FAVOURITE_TITLE,
  CARDS_NAME_3_THINGS,
  FAVS,
} from "./utils/constants";

function App() {
  return (
    <div className='App'>
      <Page title={NAME_3_THINGS_TITLE} list={CARDS_NAME_3_THINGS} />
      <Page title={YOUR_FAVOURITE_TITLE} list={FAVS} />
    </div>
  );
}

export default App;

export const NAME_3_THINGS_TITLE = "Name 3 Things";
export const YOUR_FAVOURITE_TITLE = "Your Favourite Something";
export const CARDS_NAME_3_THINGS = [
  "Name 3 things that make you happy",
  "Name 3 things that make you sad",
  "Name 3 things you would like to have but can't afford",
  "Name 3 things you can't buy with money",
  "Name 3 things you would like to get for your birthday",
  "Name 3 things you can do in a park",
  "Name 3 excuses why you didn't do your homework",
  "Name 3 things you hope will happen in the future",
  "Name 3 radical sports you would like to try",
  "Name 3 things you can do in summer",
  "Name 3 things you can do in winter",
  "Name 3 things you buy with your pocket money",
  "Name 3 countries you would like to visit",
  "Name 3 things you can't stand",
  "Name 3 things you dislike about yourself",
  "Name 3 things you like about yourself",
  "Name 3 things that make a great holiday",
  "Name 3 things you are afraid of",
  "Name 3 unusual jobs",
  "Name 3 presents that you liked most",
  "Name 3 things you don't have the courage to do",
  "Name 3 things you shouldn't do at school",
  "Name 3 activities you like to do at the weekend",
  "Name 3 unusual pets",
  "Name 3 things you like about English",
  "Name 3 presents you didn't like at all",
  "Name 3 things you don't like about English",
  "Name 3 reasons why you would like to be a celebrity",
  "Name 3 reasons why you wouldn't like to be a celebrity",
  "Name 3 things to about a teacher",
];

export const FAVS = [
  "Your favourite animal",
  "Your favourite board game",
  "Your favourite day of week",
  "Your favourite website",
  "Your favourite singer",
  "Your favourite drink",
  "Your favourite country",
  "Your favourite food",
  "Your favourite blogger",
  "Your favourite city",
  "Your favourite book",
  "Your favourite colour",
  "Your favourite film",
  "Your favourite cartoon",
  "Your favourite place",
  "Your favourite shop",
  "Your favourite subject",
  "Your favourite café",
  "Your favourite pet",
  "Your favourite snack",
  "Your favourite song",
  "Your favourite dessert",
  "Your favourite actor",
  "Your favourite actress",
  "Your favourite sport",
  "Your favourite flower",
  "Your favourite season",
  "Your favourite toy",
  "Your favourite music genre",
  "Your favourite friut",
  "Your favourite holiday",
  "Your favourite gadget",
  "Your favourite activity",
  "Your favourite number",
  "Your favourite computer game",
];

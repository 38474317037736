import Card from "../Card/Card";
import "./CardList.css";

function CardList({ list }) {
  return (
    <div className='cardList'>
      {list.map((item, index) => (
        <Card info={item} key={index} />
      ))}
    </div>
  );
}

export default CardList;
